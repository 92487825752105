
import EditForm from "../edit-form.vue";
// @ts-expect-error 
import EditActions from "./edit-actions";
import { defineComponent } from "vue";
import { FilePM, RegistrationEditPM } from "@/models";
import { RegistrationService } from "@/services";
import { routeNames } from "@/router";
// @ts-expect-error 
import { dirtyTracker } from "hestia";

export default defineComponent({  
  name: "EditRegistration",
  inject: ['api'],
  components: {
    EditForm,
    EditActions,
    dirtyTracker
  },
  data() {
    return {
      errors: [] as string[],
      disableActions: false,
      registration: null as RegistrationEditPM | null,
      typedApi: new RegistrationService(this.api),
      isDirtyTrackingEnabled: true,
      formId: "registrationForm"
    };
  },
  computed: {
    pageTitle(): string {
      return this.$route.meta.title as string;
    },
    hasErrors(): boolean {
      return !!this.errors && this.errors.length > 0;
    },
    registrationNumber(): string {
      return this.$route.params.registrationNumber as string;
    }
  },
  methods: {
    isValid(): boolean {
      const errors = this.$validate(this.formId);
      this.errors = errors;
      return errors.length === 0;
    },
    async save(submit = false) {
      if (!this.registration || !this.isValid()) return;
      this.$hardLoading();

      // Add only newly uploaded files to the "docs" property for save
      this.registration.docs = this.registration.newFiles;

      try {
        await this.typedApi.editRegistration(this.registration.id, submit, this.registration);
        this.$toast({ message: `Registration ${submit ? 'submitted' : 'saved'}`, type: 'success' });
        // Disable dirty tracking and navigate away
        this.isDirtyTrackingEnabled = false;
        this.$router.push({ name: routeNames.registrationDetails, params: { registrationNumber: this.registrationNumber }});
        // Do the page transition with loading ON. Will be dismissed on the detail page.
      } catch (error) {
        this.$errorModal(error);
        this.$doneLoading();
      }
    },
    updateFiles(files: FilePM[]) {
      if(!this.registration) return;
      this.registration.docs = files;
    },
    onFileUploaded(file: any) {
      if(!this.registration) return;
      this.registration.newFiles.push(file);
    },
    onFileDeleted({ file }: Record<string, FilePM>) {
      if(!this.registration) return;
      this.registration.newFiles = this.registration.newFiles.filter(f => file.referenceName !== f.referenceName);
    },
    async loadRegistration() {
      this.$hardLoading();
      try {
        const registrationVM = await this.typedApi.getEditDetails(this.registrationNumber);
        this.registration = new RegistrationEditPM().copyFromViewModel(registrationVM);
      } catch (error) {
        this.$errorPage(error);
      } finally {
        this.$doneLoading();
      }
    },
    cancelEdit() {
      this.$router.push({ name: routeNames.registrationDetails, params: { registrationNumber: this.registrationNumber }});
    },
  },
  mounted() {
    this.loadRegistration();
  },
});
