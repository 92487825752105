import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field is-grouped is-grouped-right" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("button", {
        type: "submit",
        class: "button is-success",
        disabled: _ctx.disabled
      }, "Submit", 8, ["disabled"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("button", {
        type: "button",
        class: "button is-link",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('save'))),
        disabled: _ctx.disabled
      }, "Save Draft", 8, ["disabled"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        type: "button",
        class: "button is-link is-light",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel'))),
        disabled: _ctx.disabled
      }, "Cancel", 8, ["disabled"])
    ])
  ]))
}