
import { defineComponent } from "vue";

export default defineComponent({
  name: "EditActions",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["submit", "save", "cancel","expire"]
})
